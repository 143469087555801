<template>
  <b-container class="phones-page">
    <masthead
      title="Phones"
      @changed="searchChanged"
    />

    <template slot="title">
      Phones
    </template>

    <ct-centered-spinner v-if="!loaded" height="260px" />

    <div v-if="loaded">
      <b-tabs card content-class="mt-3">
        <b-tab title="Active" active>
          <div v-if="virtualPhones.length > 0">
            <div v-if="loaded" class="row">
              <div
                v-for="phone in virtualPhones"
                :key="phone.id"
                class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4"
              >
                <virtual-phone-card :virtual-phone="phone" />
              </div>
            </div>
          </div>
          <div v-else>
            <p>There are currently no active phones.</p>
          </div>
        </b-tab>
        <b-tab title="Pending Removal">
          <div v-if="virtualPhonesPendingRemoval">
            <b><p>You have 45 days to reactivate a number before it is permanently deleted.</p></b>
            <virtual-phone-pending-removal-cards
              :virtual-phones-pending-removal="virtualPhonesPendingRemoval"
              :fetch-phones="fetchPhones"
            />
          </div>
          <div v-else>
            <p>There are no phones currently slotted for removal.</p>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <capture-odd-data />
    <check-virtual-phone-suspension />
  </b-container>
</template>

<script>

import Masthead from '@/components/shared/Masthead'
import VirtualPhoneCard from '@/components/VirtualPhones/VirtualPhoneCard.vue'
import VirtualPhonePendingRemovalCards from '@/components/VirtualPhones/VirtualPhonePendingRemovalCards.vue'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner.vue'
import { mapActions, mapGetters } from 'vuex'
import CaptureOddData from '@/components/CaptureOddData'
import CheckVirtualPhoneSuspension from '@/components/VirtualPhones/CheckVirtualPhoneSuspension.vue'

export default {
  name: 'Phones',
  components: { CaptureOddData, CtCenteredSpinner, VirtualPhoneCard, Masthead, VirtualPhonePendingRemovalCards, CheckVirtualPhoneSuspension },
  data () {
    return {
      query: null,
      loaded: false,
    }
  },
  computed: {
    ...mapGetters('virtualPhones',
      [
        'virtualPhonesMatchingFilter',
        'virtualPhonesPendingRemoval',
        'suspendedPhones',
        'virtualPhones',
        'virtualPhonesFinished',
        'suspendedPhonesFinished',
      ]
    ),

    virtualPhones() {
      return this.virtualPhonesMatchingFilter(this.query)
    },
  },
  async mounted () {
    await this.fetchPhones()
  },
  methods: {
    ...mapActions('virtualPhones', ['loadVirtualPhones', 'loadSuspendedPhones', 'setVirtualPhonesPendingRemoval']),

    searchChanged (searchObject) {
      this.query = searchObject.query
    },
    async fetchPhones () {
      this.loaded = false
      if (!this.virtualPhonesFinished) {
        await this.loadVirtualPhones()
      } else if (!this.suspendedPhonesFinished) {
        await this.loadSuspendedPhones()
      }

      await this.setVirtualPhonesPendingRemoval()
      this.loaded = true
    },
  },
}
</script>
