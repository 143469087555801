var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "phones-page" },
    [
      _c("masthead", {
        attrs: { title: "Phones" },
        on: { changed: _vm.searchChanged },
      }),
      _c("template", { slot: "title" }, [_vm._v("\n    Phones\n  ")]),
      !_vm.loaded
        ? _c("ct-centered-spinner", { attrs: { height: "260px" } })
        : _vm._e(),
      _vm.loaded
        ? _c(
            "div",
            [
              _c(
                "b-tabs",
                { attrs: { card: "", "content-class": "mt-3" } },
                [
                  _c("b-tab", { attrs: { title: "Active", active: "" } }, [
                    _vm.virtualPhones.length > 0
                      ? _c("div", [
                          _vm.loaded
                            ? _c(
                                "div",
                                { staticClass: "row" },
                                _vm._l(_vm.virtualPhones, function (phone) {
                                  return _c(
                                    "div",
                                    {
                                      key: phone.id,
                                      staticClass:
                                        "col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4",
                                    },
                                    [
                                      _c("virtual-phone-card", {
                                        attrs: { "virtual-phone": phone },
                                      }),
                                    ],
                                    1
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ])
                      : _c("div", [
                          _c("p", [
                            _vm._v("There are currently no active phones."),
                          ]),
                        ]),
                  ]),
                  _c("b-tab", { attrs: { title: "Pending Removal" } }, [
                    _vm.virtualPhonesPendingRemoval
                      ? _c(
                          "div",
                          [
                            _c("b", [
                              _c("p", [
                                _vm._v(
                                  "You have 45 days to reactivate a number before it is permanently deleted."
                                ),
                              ]),
                            ]),
                            _c("virtual-phone-pending-removal-cards", {
                              attrs: {
                                "virtual-phones-pending-removal":
                                  _vm.virtualPhonesPendingRemoval,
                                "fetch-phones": _vm.fetchPhones,
                              },
                            }),
                          ],
                          1
                        )
                      : _c("div", [
                          _c("p", [
                            _vm._v(
                              "There are no phones currently slotted for removal."
                            ),
                          ]),
                        ]),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("capture-odd-data"),
      _c("check-virtual-phone-suspension"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }